
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.template::v-deep {
    .template-item {
        border: 3px solid transparent;
        padding: 0;

        &:not(.is-selected) {
            cursor: pointer;
        }

        &.is-selected {
            border-color: $primary-color;
        }
    }
}
