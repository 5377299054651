
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.video-template-preview::v-deep {
    .v-label {
        color: $secondary-color;
    }
}
