
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.video-template::v-deep {
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    .v-image {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
